import React from 'react';
import './App.css'; // Import CSS for styling
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const navItems = [
  { href: "#popular", label: "Popular" },
  { href: "#birthday", label: "Birthday" },
  { href: "#holidays", label: "Holidays" },
  { href: "#encouragement", label: "Encouragement" },
  { href: "#love", label: "Love" },
  { href: "#friends-family", label: "Friends & Family" },
  { href: "#congrats", label: "Congrats" },
  { href: "#professional", label: "Professional" },
  { href: "#artist-collections", label: "Artist Collections" },
  { href: "#sale", label: "Sale" }
];

const bottomNavItems = [
  { href: "#shop", label: "Shop", icon: "fas fa-envelope" },
  { href: "#discover", label: "Discover", icon: "fas fa-eye" },
  { href: "#outbox", label: "Outbox", icon: "fas fa-pencil-alt" },
  { href: "#events", label: "Events", icon: "fas fa-calendar-alt" },
  { href: "#contacts", label: "Contacts", icon: "fas fa-globe" }
];

const App: React.FC = () => {
  return (
    <div className="app">
      <header className="header">
        <nav className="main-nav">
          <h1>WPost</h1>
        </nav>
        <nav className="sub-nav">
          <ul>
            {navItems.map(item => (
              <li key={item.href}>
                <a href={item.href}>{item.label}</a>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <main className="content">
        <h2>🤓 WPost is already open 🤓</h2>
      </main>
      <nav className="bottom-nav">
        <ul>
          {bottomNavItems.map(item => (
            <li key={item.href}>
              <a href={item.href}>
                <i className={item.icon}></i>
                <span>{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default App;